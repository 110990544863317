<!--通行模式匹配设置-->
<template>
  <div id="matchingSet" v-loading="loading" :element-loading-text="$t('information.a1')">
    <!-- 头部 -->
    <div class="header">
      <div class="header-title">
        {{ $route.query.node }}
      </div>
      <a-icon type="close" @click="returnTo" style="font-size:16px"/>
    </div>
    <!-- 内容区域 -->
    <div class="main">
      <!-- 侧边栏 -->
      <div class="sidebar">
        <div class="sidebar-main">
          <a-menu mode="inline" :inlineIndent="10">
            <a-menu-item v-for="item in sidebarData" :key="item.siteId" :title="item.siteName" @click="navigation(item)">
                {{ item.siteName }}
            </a-menu-item>
          </a-menu>
        </div>
      </div>
      <div class="main-right">
        <!-- 空区域 -->
        <div v-if="indexData.siteName == undefined" class="empty">
          <a-empty>
            <span class="emptyfont">{{ $t('information.a281') }}</span>
          </a-empty>
        </div>
        <!-- 通行模式设置区域 -->
        <div v-else class="content">
          <div class="tableLayout">
            <div class="vessel">
              <div class="vessel-title">
                <span>{{ $t('information.a282') }}</span>
                <a-input-search v-model="search" :placeholder="$t('information.a284')" style="width: 200px" allow-clear @change="onSearch"/>
              </div>
              <div class="vessel-table">
                <a-table :columns="columns" :data-source="allData" size="small" :pagination="pagination" :rowKey="(record) => record.id">
                  <template slot="operate" slot-scope="text, record, index">
                    <a-button type="primary" @click="addData(record,index)">
                      {{ $t('information.a35') }}
                    </a-button>
                  </template>
                </a-table>
              </div>
            </div>
            <div class="vessel">
              <div class="vessel-title">
                <span>{{ $t('information.a283') }}</span>
              </div>
              <div class="vessel-table">
                <a-table :columns="columns" :data-source="registeredData" size="small" :pagination="false" :rowKey="(record) => record.id">
                  <template slot="operate" slot-scope="text, record, index">
                      <a-button type="primary" @click="deleteData(record,index)" :disabled="!record.amend">
                        {{ $t('information.a31') }}
                      </a-button>
                    </template>
                </a-table>
              </div>
            </div>
          </div>
          <div class="setBut">
            <a-button type="primary" @click="setPatternMatching">
              {{ $t('information.a208') }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAccessRoleSiteList,getTrafficModeData,setTrafficMode } from '../../api/info'

export default {
  name: "matchingSet",
  data(){
    return{
      loading: false,
      sidebarData: [],
      search:'',
      indexData: {},
      allDataCopy:[],
      allData:[],
      registeredData:[],
      columns:[
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
          align:'center'
        },
        {
          title: this.$t('information.a215'),
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: this.$t('information.a188'),
          scopedSlots: { customRender: 'operate' },
          align:'center'
        },
      ],
      pagination: {
        total: 0, //数据总数
        pageSize: 30, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
        showQuickJumper: false, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
    }
  },
  // 获取侧边栏数据
  mounted(){
    this.loading = true
    const data = { personGrId:this.$route.query.id }
    console.log(data)
    getAccessRoleSiteList(data)
    .then(res=>{
      console.log(res)
      const { data } = res
      this.sidebarData = data
      this.loading = false
    })
    .catch(err=>{
      console.log(err)
      this.loading = false
    })
  },
  methods:{
    // 根据站点获取对应的数据
    navigation(value){
      this.search = ''
      this.indexData = value
      this.loading = true
      const data = { personGrId:this.$route.query.id,siteId:value.siteId}
      getTrafficModeData(data)
      .then(res=>{
        const { data:{accessRoleBeans,registeredAccessRoleBeans,topRegisteredAccessRoleBeans} } = res
        for(let i = 0;i<registeredAccessRoleBeans.length;i++){
          registeredAccessRoleBeans[i].amend = true
        }
        for(let i = 0;i<topRegisteredAccessRoleBeans.length;i++){
          topRegisteredAccessRoleBeans[i].amend = false
        }
        const registeredData = [...topRegisteredAccessRoleBeans,...registeredAccessRoleBeans]
        const _allDataCopy = JSON.stringify(accessRoleBeans)
        const allDataCopy = JSON.parse(_allDataCopy)
        this.allData = accessRoleBeans
        this.allDataCopy = allDataCopy
        this.registeredData = registeredData
        this.loading = false
      })
      .catch(err=>{
        console.log(err)
        this.loading = false
      })
    },
    // 页面回退方法
    returnTo() {
      this.$router.go(-1);
    },
    // 搜索方法
    onSearch(e){
      const val = e.target.value
      const allDataCopy = this.allDataCopy
      let data = allDataCopy.filter((value) => value.name.indexOf(val) !== -1)
      this.allData = data
    },
    // 数据添加方法
    addData(record,index){
      let obj = record
      obj.amend = true
      const length = this.registeredData.length
      if(length < 5){
        this.registeredData.push(obj)
        this.allData.splice(index,1)
        let data = this.allDataCopy.filter((value) => value.id !== record.id)
        this.allDataCopy = data
      }else{
        this.$message.warning(this.$t('information.a285'),5);
      }
    },
    // 数据删除方法
    deleteData(record,index){
      const _data = JSON.stringify(record)
      const data = JSON.parse(_data)
      this.allData.unshift(data)
      this.allDataCopy.unshift(data)
      this.registeredData.splice(index,1)
    },
    // 通行模式匹配设置方法
    setPatternMatching(){
      this.loading = true
      let registeredData = this.registeredData
      const arr = []
      for(let i = 0;i<registeredData.length;i++){
        if(registeredData[i].amend){
          arr.push(registeredData[i])
        }
      }
      const data = { personGrId:this.$route.query.id,siteId:this.indexData.siteId,registeredAccessRoleBeans:arr}
      setTrafficMode(data)
      .then(res=>{
        if(res.errorCode == '00'){
          this.$message.success(res.msg,5)
        }else{
          this.$message.error(res.msg,5)
        }
        this.loading = false
      })
      .catch(err=>{
        console.log(err)
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
#matchingSet{
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px #bdbcbc;
  overflow: hidden;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0px 2px 5px rgba(12, 12, 12, 0.15);
}
.header-title{
  display: flex;
  align-items: center;
  font-size: 16px;
}
.header-title::before{
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background-color: #7682ce;
  margin-right: 15px;
}
.main{
  display: flex;
  width: 100%;
  height: calc(100% - 50px);
  padding: 20px 20px 0px 20px;
}
.main-right{
  width: calc(100% - 270px);
  height: 100%;
}
.sidebar{
  position:relative;
  width: 250px;
  height: 100%;
  margin-right: 20px;
  border-right: 1px solid rgba(12, 12, 12, 0.15);
  overflow: auto;
}
.sidebar-main{
  position: absolute;
  min-width: 250px;
}
.content{
  width: 100%;
  height: 100%;
}
.contentTitle{
  font-size: 16px;
  margin-bottom: 15px;
}
.tableLayout{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 52px);
}
.vessel{
  width: calc(50% - 10px);
  height: 100%;
}
.vessel-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  font-size: 14px;
  background-color: #f3f3f5;
  padding: 10px 20px;
  border-radius: 3px;
}
.vessel-table{
  height: calc(100% - 52px);
  overflow: auto;
}
.setBut{
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}
.empty{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.emptyfont{
    font-size: 20px;
    color: #7682CE;
}
</style>